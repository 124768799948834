import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea"
export default class extends Controller {
  connect() {
    const textarea = this.element;

    textarea.onkeyup = function(e) {
      textarea.style.height = "auto";
      let scHeight = e.target.scrollHeight;
      textarea.style.height = `${scHeight}px`;
    }
  }
}
