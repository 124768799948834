import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="variant-images"
export default class extends Controller {
  connect() {
    // this.files = [];
    // this.element.addEventListener("change", this.handleFileSelect.bind(this));
    // document.querySelector("form#art_form").addEventListener("submit", this.handleSubmit.bind(this));
  }

  handleFileSelect(event) {
    // Append new files to existing files
    this.files.push(...Array.from(event.target.files));
  }

  handleSubmit(event) {
    console.log("running?")
    console.log(this.files);
    console.log(this.element.files);
    event.preventDefault()

    this.element.files = this.files;

    // Then, manually submit the form
    let forms = document.querySelector("form#art_form");
    forms.submit();
  }
}
