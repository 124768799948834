import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["submit"]

  connect() {

  }

  handleFilterChange() {
    document.getElementById("filters").requestSubmit();
  }

  handleCategoryClick(event) {
    event.preventDefault();
    const category_id = event.currentTarget.dataset.category;
    const parentElement = event.currentTarget.parentElement;
    const input = parentElement.querySelector("#" + "art_category_" + category_id);
    input.click();
  }

  handlePageChange(event) {
    event.preventDefault();
    const page = event.currentTarget.dataset.page;
    const input = document.getElementById("page");
    input.value = page;
    document.getElementById("filters").requestSubmit();
  }

  handleFilterRemovePrice(event) {
    event.preventDefault();
    const price_id = event.currentTarget.dataset.price;
    const input = document.getElementById(price_id);
    input.value = "";
    document.getElementById("filters").requestSubmit();
  }
}
