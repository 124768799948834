import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropzone"
export default class extends Controller {
  connect() {
    var dragArea = this.element;
    var fileInput = document.getElementById("post_image");
    var filesInput = document.getElementById("post_images");
    var uploadBtn = dragArea.getElementsByClassName("upload-btn")[0];

    dragArea.ondrop = function(e) {
      e.preventDefault();
      this.className = "dropzone-img position-relative";
      filesInput.files = (e.dataTransfer.files);
      filesInput.onchange();
    };

    dragArea.ondragover = function() {
      if (this.className.includes("dropzone-img")) {
        this.className = "dropzone-img dragover position-relative";
      } else {
        this.className = "dropzone dragover position-relative";
      }
      if (uploadBtn) {uploadBtn.style.opacity = "1";}
      return false;
    };

    dragArea.onmouseover = function() {
      if (this.className.includes("dropzone-img")) {
        this.className = "dropzone-img dragover position-relative";
      } else {
        this.className = "dropzone dragover position-relative";
      }
      if (uploadBtn) {uploadBtn.style.opacity = "1";}
      return false;
    };

    dragArea.ondragleave = function() {
      if (this.className.includes("dropzone-img")) {
        this.className = "dropzone-img position-relative";
      } else {
        this.className = "dropzone position-relative";
      }
      if (uploadBtn) {uploadBtn.style.opacity = "0.6";}
      return false;
    }; 

    dragArea.onmouseleave = function() {
      if (this.className.includes("dropzone-img")) {
        this.className = "dropzone-img position-relative";
      } else {
        this.className = "dropzone position-relative";
      }
      if (uploadBtn) {uploadBtn.style.opacity = "0.6";}
      return false;
    };

    dragArea.addEventListener("click", selectFile);

    function selectFile() {
      if (dragArea.parentElement.id == "variant_images") {
        // secondary
        filesInput.click();
        const existingFiles = Array.from(filesInput.files);
        filesInput.onchange = function() {
          var validExtensions = ["image/jpeg", "image/jpg", "image/png"];
          var files = filesInput.files;

          if (files) {
            // Merge old and new files
            const allFiles = existingFiles.concat(Array.from(files));
            // Create a new FileList
            const newFileList = new DataTransfer();
            allFiles.forEach(file => newFileList.items.add(file));
            // Assign new FileList to input
            filesInput.files = newFileList.files
            var files = filesInput.files;

            let childrenDivs = dragArea.parentElement.querySelectorAll("div");
            // Removes existing vaariant_images from the DOM
            for (let i = 0; i < childrenDivs.length - 1; i++) {
              dragArea.parentElement.removeChild(childrenDivs[i]);
            }
            // Adds variant_images from the input
            for (var i = 0; i < files.length; i++) {
              if (validExtensions.includes(files[i].type)) {
                var reader = new FileReader();
                reader.onload = (function(index) {
                  return function(e) {
                    const deleteBtn = document.createElement("button");
                    deleteBtn.type = "button";
                    deleteBtn.className = "btn btn-danger btn-sm position-absolute top-0 end-0";
                    //deleteBtn.dataset.action = "dropzone#deleteFile";
                    deleteBtn.innerHTML = '<i class="bi bi-x"></i>';
                    deleteBtn.addEventListener('click', () => deleteFile(index));
                    var imgTag = `<img src="${e.target.result}">`;

                    if (dragArea.querySelector("img")) {                    
                      dragArea.innerHTML = imgTag;
                    } else {
                      var div = document.createElement("div");
                      div.id = "variant_image_" + index;
                      div.className = "dropzone-img position-relative";
                      //div.setAttribute("data-controller", "dropzone");
                      div.style.width = "25%";
                      div.innerHTML = imgTag;
                      div.appendChild(deleteBtn);
                      dragArea.before(div);
                    }
                  }
                })(i);

                reader.readAsDataURL(files[i]);
              } else {
                alert("This file is not an Image");
              };
            }
          }
        };
      } else {
        // main
        fileInput.click();
        fileInput.onchange = function() {
          var validExtensions = ["image/jpeg", "image/jpg", "image/png"];
          if (validExtensions.includes(fileInput.files[0].type)) {
            var reader = new FileReader();
            reader.onload = function() {
              var imgTag = `<img src="${reader.result}">`;
              dragArea.innerHTML = imgTag;
              dragArea.className = "dropzone-img position-relative";
            };
    
            reader.readAsDataURL(fileInput.files[0]);
          } else {
            alert("This file is not an Image");
          };
        };
      }
    };

    function deleteFile(index) {
      const existingFiles = Array.from(filesInput.files);
      existingFiles.splice(index, 1);

      const newFileList = new DataTransfer();
      existingFiles.forEach(file => newFileList.items.add(file));
      // Assign new FileList to input
      filesInput.files = newFileList.files
      // Removes variant_image from the DOM
      document.getElementById("variant_image_" + index).remove();
    }
  }
}
