import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:frame-load", this.showModal);
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-load", this.showModal);
  }

  showModal = () => {
    const buttonElement = this.element.querySelector(".activate-modal");
    if (buttonElement) {
      buttonElement.click();
    }
  }

  reset(event) {
    this.element.innerHTML = "";
    this.element.removeAttribute("src");
    this.element.removeAttribute("complete");
  }

  submitForm() {
    const button = this.element.getElementById("submit_modal_form")
    button.click();
  }
}
