import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="followers"
export default class extends Controller {
  connect() {
    var followers = document.getElementById("followers");
    var following = document.getElementById("following_users");
    var tab_indicator = document.getElementById("tab_indicator");
    var followers_btns = document.getElementsByClassName("followers-btn");
    var following_btns = document.getElementsByClassName("following-btn");

    for(var i = 0; i < followers_btns.length; i++) {
      var btn = followers_btns[i]
      btn.onclick = function() {
        followers.style.display = "block";
        following.style.display = "none";
        tab_indicator.style.left = `calc(calc(100% / 2) * 0  + calc(100% / 12))`;
      };
    }

    for(var i = 0; i < following_btns.length; i++) {
      var btn = following_btns[i]
      btn.onclick = function() {
        followers.style.display = "none";
        following.style.display = "block";
        tab_indicator.style.left = `calc(calc(100% / 2) * 1 + calc(100% / 12))`;
      };
    }
  }
}
