import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-variant"
export default class extends Controller {
  connect() {
    const selectElement = this.element.querySelector("select");

    selectElement.addEventListener("change", () => {
      this.element.requestSubmit();
      // I do not know why this.element.submit() does not work, but this does
    });
  }
}
