import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs";

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = ["source", "output", "cropButton", "input", "inputButton"];
  connect() {
    this.sourceTarget.addEventListener("load", () => {
      setTimeout(() => {  this.createCropper(); }, 500);
    });

    this.inputTarget.addEventListener("change", () => {
      this.showPfp();
    });
  }

  createCropper() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    
    this.cropper = new Cropper(this.sourceTarget, {
      autoCropArea: 1,
      aspectRatio: 1,
      viewMode: 2,
      dragMode: "move",
      responsive: true,
    });
  }

  click = (e) => {
    this.postToAPI(this.cropper.getCroppedCanvas().toDataURL());
  };

  postToAPI(croppedData) {
    const user_id = this.data.element.dataset["imagesId"];
    const dataURL = croppedData;

    fetch(`/croppable/${user_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        user: {
          pfp: dataURL,
        },
      }),
    });
  }

  selectFilePfp() {
    this.inputTarget.click();
  }

  showPfp() {
    const file = this.inputTarget.files && this.inputTarget.files[0];
  
    // Check if file is selected and is an image
    if (file && file.type.match(/^image\//)) {
      var reader = new FileReader();
  
      reader.onload = (e) => {
        this.sourceTarget.src = e.target.result;
      };
  
      reader.readAsDataURL(file);
    } else {
      alert("Seleccione un archivo de imagen.");
    }
  }
}
