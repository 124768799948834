import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="header"
export default class extends Controller {
  connect() {
    const headerHeight = this.element.offsetHeight;
    document.body.style.paddingTop = `${headerHeight}px`;

    const dropdown = document.getElementById('dropdown');
    const dropdownButton = document.getElementById('dropdown-button');
    const dropdownContent = document.getElementById('dropdown-content');

    setActiveButton();


    dropdownButton.addEventListener('click', function() {
      toggleDropdown();
    });

    dropdown.addEventListener('click', function(event) {
      event.stopPropagation();
    });

    document.addEventListener('click', function(event) {
      if (dropdown.classList.contains('active'))
      {
        const isClickedOutside = !dropdownButton.contains(event.target) && !dropdownContent.contains(event.target);
        if (isClickedOutside) {
          toggleDropdown();
        }
      }
    });

    function toggleDropdown() 
    {
      dropdown.classList.toggle("active");
    }

    function setActiveButton() 
    {
      const path = window.location.href;
      const buttons = document.querySelector('.menu').querySelectorAll('.menu-item')
      for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i];
        if (button.parentNode.action == path)
        {
          button.classList.add('active');
        }
      }
    }
  }
}
