import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tablist"
export default class extends Controller {
  static targets = ["tab", "item", "underline"]

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const tabToOpen = parseInt(urlParams.get('tab')) || 0;

    // Show underline under the first tab by default
    this.moveUnderline(tabToOpen);
    this.showItem(tabToOpen);

    this.tabTargets.forEach((tab, index) => {
      tab.addEventListener("click", () => {
        this.showItem(index);
        this.moveUnderline(index);
      });
    });
  }

  showItem(index) {
    // Hide all items first
    this.itemTargets.forEach(item => {
      item.classList.remove("active");
    });

    // Show the item corresponding to the clicked tab
    this.itemTargets[index].classList.add("active");
  }

  moveUnderline(index) {
    const selectedTab = this.tabTargets[index];
    const underline = this.underlineTarget;

    const tabOffsetLeft = selectedTab.offsetLeft;
    const tabWidth = selectedTab.offsetWidth;

    underline.style.left = `${tabOffsetLeft}px`;
    underline.style.width = `${tabWidth}px`;
  }
}
