import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file"
export default class extends Controller {
  connect() {
    var fileInput = document.getElementById("post_file");
    var validImageExtensions = ["image/jpeg", "image/jpg", "image/png"];
    var validVideoExtensions = ["video/mp4"];

    document.getElementById("image_btn").onclick = selectImage;
    document.getElementById("video_btn").onclick = selectVideo;

    function selectImage() {
      fileInput.accept = validImageExtensions;
      fileInput.click();
    }

    function selectVideo() {
      fileInput.accept = validVideoExtensions;
      fileInput.click();
    }

    // Change post_type
    document.getElementById("publish_form").onsubmit = DoSubmit;

    function DoSubmit() {
      if (fileInput.files.length != 0) {
        if (validImageExtensions.includes(fileInput.files[0].type)) {
          fileInput.name = "post[image]";
          document.getElementById("post_type").value = "Image post";
        } else if (validVideoExtensions.includes(fileInput.files[0].type)) {
          fileInput.name = "post[video]";
          document.getElementById("post_type").value = "Video post";
        }
      }
    }

    // Preview uploaded image
    fileInput.onchange = function() {
      showImage(this);
    }

    function showImage(input) {
      const file = input.files && input.files[0];

      if (file && file.type.match(/^image\//)) {
        var reader = new FileReader();
  
        reader.onload = function (e) {
          document.getElementById("post_img_preview").src = e.target.result;
        }
  
        reader.readAsDataURL(input.files[0]);
      } else {
        alert("Seleccione un archivo de imagen o video.");
      }
    }
  }
}
