import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.remove_alert.bind(this))
  }

  remove_alert() {
    this.element.style.opacity = '0';
    setTimeout(() => this.element.remove(), 500);
  }
}
