import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="formsteps"
export default class extends Controller {
  connect() {
    
    //define variables
    var artist_check = document.getElementById("artist-check");
    var sale_check = document.getElementById("sale-check");
    var art_sale_type = document.getElementById("art_inventory_type");
    var num_of_variants = document.getElementById("num_of_versions");
    var art_packaging_check = document.getElementById("art_packaging");
    var acc = document.getElementsByClassName("art-accordion");     
    var form = document.getElementById("art_form");
    var i;


    //RUN FUCTIONS FOR FORM SETUP
    toggleSaleContainer();
    toggleVariantNumField();
    toggleVariantFields();
    toggleProductFields();
    toggleAddressFields()
    togglePackagingWeight()


    //DEFINE EVENTS
    artist_check.onclick = function() { isArtist(artist_check.getAttribute("data-username")); };
    
    //check if the type of art allows for multiple variants, if so, reveal the number of variants field
    art_sale_type.onclick = function() { toggleVariantNumField(); };

    //check if art is for sale, if it is, activate the sales details panel
    sale_check.addEventListener("change", (event) => 
      {
        toggleSaleContainer();
        toggleProductFields();
        toggleAddressFields()
      }
    )

    //check number of variants and add or hide variant fields
    num_of_variants.addEventListener("change", (event) => 
      {
        var og_height = document.getElementById("sales-panel").scrollHeight;

        toggleVariantFields();
        toggleProductFields();
        adjustAccSize(document.getElementById("sales-panel"), og_height);
        adjustAccSize(document.getElementById("sales2-panel"), og_height);
      }
    )

    art_packaging_check.addEventListener("change", (event) => 
      {
        togglePackagingWeight();
      }
    )
    
    document.getElementById("all_variants").addEventListener("change", (event) => 
      {
        toggleProductSize(document, event);
        toggleSaleLabels(document, event);
      }
    )

    document.getElementById("all_products").addEventListener("change", (event) => 
      {
        toggleSaleLabels(document, event);
      }
    )

    //disable artist field when the form is submitted
    form.addEventListener('submit', function() {
      var text_field = document.getElementById("exampleInputName4");
      text_field.disabled = false;
    });

    document.getElementById("submit_btn").addEventListener("click", (event) =>
      {
        openDropdownsValidation();
      }
    )


    //DEFINE FUNCTIONS
    function isArtist(username)
    {
      var text_field = document.getElementById("exampleInputName4");
      if (artist_check.checked)
      {
        text_field.value = username;
        text_field.disabled = true;
      }
      else
      {
        text_field.disabled = false;
      }
    }

    function adjustAccSize(panel, original_size)
    {
      if (original_size == panel.scrollHeight)
      {
        setTimeout(function() {adjustAccSize(panel, original_size)}, 1);        
      }
      else
      {
        panel.style.maxHeight = panel.scrollHeight + "px"; 
      }
    }

    function toggleSaleContainer()
    {
      const displayStyle = sale_check.checked ? "block" : "none";
      var sale_div = document.getElementById("sales2");
      var address_div = document.getElementById("address");

      sale_div.style.display = displayStyle;
      address_div.style.display = displayStyle;

      // Find all input elements within the additionalFieldsTarget and set their required attribute
      sale_div.querySelectorAll('input').forEach(input => {
        input.required = sale_check.checked;
      });
    }

    function toggleVariantFields()
    {
      var variant_num = parseInt(num_of_variants.value);
      var variants_container = document.getElementById("all_variants");

      for (var i = 0; i < 5; i++)
      {
        var field_id = "#new_variant-" + i.toString();
        var variant_field = variants_container.querySelector(field_id);

        if (i < variant_num)
        {
          variant_field.style.display = "grid";
          addRequiredAttributeToInputs(variant_field);
          
        }
        else
        {
          variant_field.style.display = "none";
          removeRequiredAttributeFromInputs(variant_field);
        }
      }
    }

    function toggleProductFields()
    {
      if (!sale_check.checked)
      {
        var sales2_container = document.getElementById("sales2");
        removeRequiredAttributeFromInputs(sales2_container);
      }
      else
      {
        var variant_num = parseInt(num_of_variants.value);
        var products_container = document.getElementById("all_products");
        for (var i = 0; i < 5; i++)
        {
          var field_id = "#new_product-" + i.toString();
          var product_field = products_container.querySelector(field_id);

          if (i < variant_num)
          {
            product_field.style.display = "grid";
            addRequiredAttributeToInputs(product_field);
            
          }
          else
          {
            product_field.style.display = "none";
            removeRequiredAttributeFromInputs(product_field);
          }
        }
        document.getElementById("art_packaging").setAttribute("required", "required");
      }
    }

    function toggleAddressFields()
    {
      var address_container = document.getElementById("address-panel");
      if (!sale_check.checked)
      {
        removeRequiredAttributeFromInputs(address_container);
      }
      else
      {
        var inputs = address_container.querySelectorAll("input.require, select.require");
        inputs.forEach(function(input) {
          input.setAttribute("required", "required");
        });
      }
    }

    function toggleVariantNumField()
    {
      var variants_check_container = document.getElementById("art_variants_num");
      if (art_sale_type.value == "0")
      {
        variants_check_container.style.display = "flex";
      }
      else
      {
        variants_check_container.style.display = "none";
        num_of_variants.value = 1;
        toggleVariantFields();
        toggleProductFields();
      }
    }

    function togglePackagingWeight()
    {
      var packaging_weight = document.getElementById("art_packaging_weight")
      if (document.getElementById("art_packaging").value == "2")
      {
        packaging_weight.setAttribute("required", "required");
        packaging_weight.disabled = false;
      }
      else
      {
        packaging_weight.removeAttribute("required");
        packaging_weight.value = '';
        packaging_weight.disabled = true;
      }
    }

    function removeRequiredAttributeFromInputs(container) 
    {
      var inputs = container.querySelectorAll("input, select");
      inputs.forEach(function(input) {
        input.removeAttribute("required");
      });
    }
    
    function addRequiredAttributeToInputs(container) 
    {
      var inputs = container.querySelectorAll("input, select");
      inputs.forEach(function(input) {
        input.setAttribute("required", "required");
      });
    }

    function toggleProductSize(document, event)
    {
      let id = getVariantProductIdNum(event.target.parentElement.parentElement.id);
      let variant_parent = document.getElementById("new_variant-" + id);
      let product_parent = document.getElementById("new_product-" + id);

      var height = variant_parent.querySelector("#height-field").value;
      var length = variant_parent.querySelector("#length-field").value;
      var width = variant_parent.querySelector("#width-field").value;

      let check = [height.length, length.length, width.length]
      if (!check.some(item => item === 0))
      {
        product_parent.querySelector("#product-size-label").innerText = `${height} x ${length} x ${width} cm`;
      }
      else
      {
        product_parent.querySelector("#product-size-label").innerText = "Medidas incompletas";
      }
    }

    function toggleSaleLabels(document, event)
    {
      let id = getVariantProductIdNum(event.target.parentElement.parentElement.id);
      let variant_parent = document.getElementById("new_variant-" + id);
      let product_parent = document.getElementById("new_product-" + id);

      let height = variant_parent.querySelector("#height-field").value;
      let length = variant_parent.querySelector("#length-field").value;
      let width = variant_parent.querySelector("#width-field").value;
      let weight = variant_parent.querySelector("#weight-field").value;
      let price = product_parent.querySelector("#price-field").value;
      let packaging_type = document.getElementById("art_packaging").value;
      let packaging_check = packaging_wt_check(packaging_type);

      var check = [height.length, length.length, width.length, weight.length, price.length, packaging_type.length, packaging_check]

      if (!check.some(item => item === 0))
      {
        product_parent.querySelector("#recieve-label").innerText = "$" + (parseFloat(price) * 0.7).toFixed(2);
      }
    }

    function getVariantProductIdNum(id)
    {
      let pattern = /[0-4]/
      return id.match(pattern)[0]
    }

    function packaging_wt_check(packaging_type)
    {
      var packaging_wt = document.getElementById("art_packaging_weight").value
      if (packaging_type == "2") 
      {
        if (packaging_wt.length != 0)
        {
          return 1
        }
      }
      else
      {
        return 1
      }
      return 0
    }

    for (i = 0; i < acc.length; i++)
    {
      acc[i].addEventListener("click", function() {
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight)
        {
          panel.style.maxHeight = null;
          panel.style.border = "0px solid #D9D9D9"
        } 
        else 
        {
          panel.style.maxHeight = panel.scrollHeight + "px";
          panel.style.border = "1px solid #D9D9D9"
        }
      });
    }

    function openDropdownsValidation()
    {
      for (i = 0; i < acc.length; i++)
      {
        var panel = acc[i].nextElementSibling;
        var inputs = panel.querySelectorAll("input:required, select:required");

        inputs.forEach(function(input) {
          // Could improve it by adding the validation of each input fo example min and max values of num inputs
          if (input.value.trim() === '' || input.value.trim() === '0') {
            if (!panel.style.maxHeight) 
            {
              panel.style.maxHeight = panel.scrollHeight + "px";
              panel.style.border = "1px solid #D9D9D9"
            }
          }
        });
      }
    }
  }
}
