import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-toggle"
export default class extends Controller {
  static targets = ["unhide"]

  toggle(e) {
    const passwordField = this.unhideTarget;
    let svgElement;

    // Check if the clicked element is the SVG or a child of it
    if (e.target.tagName.toLowerCase() === 'svg') {
      svgElement = e.target;
    } else {
      // Find the parent SVG element
      svgElement = e.target.closest('svg');
    }

    if (passwordField.type === "password") {

      svgElement.classList.remove('fa-eye-slash');
      svgElement.classList.add('fa-eye');
      passwordField.type = "text";
    } else {
      svgElement.classList.remove('fa-eye');
      svgElement.classList.add('fa-eye-slash');
      passwordField.type = "password";
    }
  }
}
